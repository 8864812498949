.fire-money {
  position: relative;
  bottom: 60px;
}

.give-dat {
  height: 200px;
  animation:rotateY 10s linear infinite;
}

.green-money {
  position: relative;
  bottom: 30px;
}

@keyframes rotateY {
  100% {
    transform:rotateY(720deg);
  }
}