
.slogan {
  position: relative;
  height: 8vw;
}

.navigation-control {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1em;
}

.fa-map-pin {
  stroke: black;
  stroke-width: 20;
}

.travel-border {
  overflow: auto;
}

.mapboxgl-popup-content {
  width: 300px;
}

.travel-img {
  max-height: 24vh;
}

.mapboxgl-popup-close-button {
  font-size: 25px;
}

.comments-pin {
  font-size: 0.9rem;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
}