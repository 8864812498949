$orange: #C9600E;

.gdimp {
  .slogan {

    .first,
    .second,
    .third {
      font-size: calc(1rem + 5vw);
      font-family: Verdana, serif;
      font-weight: 600;
      margin: 0;
    }

    .first {
      text-align: left;
      color: red;
      position: absolute;
      top: -1px;
      left: 8px;
    }

    .second {
      position: absolute;
      top: 3px;
      left: 7px;
      color: lightgreen;
      mix-blend-mode: darken;
    }

    .third {
      position: absolute;
      top: 6px;
      left: 3px;
      color: cyan;
      mix-blend-mode: color-burn;
    }
  }

  .navbar-light .navbar-toggler {
    border: 5px solid transparent;
    border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
    border-image-slice: 1;
    &:after{
      content: '';
      width: 200px;
      background: black;
      z-index: -1;
    }
  }

  .nav-item {
    &:hover {
      background: white;

      .nav-link {
        color: black;
      }
    }
    background: black;
    &:not(:last-child) {
      border-bottom: 1px solid white;
    }
    .nav-link {
      color: white;
      font-weight: 600;
    } 
  }
}

.logo {
  width: 75vw;
  height: 10vw;
}

.normal {
  .nav-item {
    &:hover {
      background: $orange !important;
      
      .nav-link {
        color: white !important;
        font-weight: 600;
      }
    }
  }

  .navbar-light .navbar-toggler {
    border: 3px solid #C9600E;
  }

  .nav-item:nth-child(even) {
    background: #f2f2f2;
    .nav-link {
      color: #757575;
      font-weight: 600;
    }
    
  }
  .nav-item:nth-child(odd) {
    background: #757575;
    .nav-link {
      color: #f2f2f2;
      font-weight: 600;
    }
  }
}


