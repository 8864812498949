.home-container {
  padding: 20px;
}

 .star {
  width: 170px;
}

.you-did-it {
  position: relative;
  left: 50px;
  font-size: 40px;
  bottom: 110px;
}

.aristocats {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  animation:spin 8s linear infinite;
}

@keyframes spin {
  0% {
    top: -200px;
    left: -200px;
  }
  100% {
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
    top: 100vh;
    left: 100vw;
  }
}
